import React, { useState, Fragment } from 'react'
import Select from 'react-select'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import AddClassesTable from 'components/admin/AddClassesTable'
import { Link, navigate } from 'gatsby'
import { Icon } from '@iconify/react'

import { addUser, getAllUsers } from 'API/user'
import { UserType } from 'types/UserAdditionMetaData'
import { getUserData } from 'API/user'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ApplicationStateActions } from 'redux/slices/ApplicationState'
import { addTeacher, addStudent, listCourses } from 'API/course'
import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import './index.scss'
import { handleApi } from 'API/handleApiCall'
import { PromiseType } from 'utility-types'

const UpdatePassword: React.FC<{ e: string }> = ({ e: uid }) => {
	const [isVisible, setIsVisible] = useState(false)
	const [password, setPassword] = useState('')

	return (
		<>
			{isVisible ? (
				<>
					{/* {uid} */}

					<div className=''>
						<form
							method='post'
							onSubmit={async (e) => {
								e.preventDefault()
								e.stopPropagation()
								e.nativeEvent.stopImmediatePropagation()
								try {
									if (password.length < 8 || password.length > 32) {
										toast('Password length should be greater than or equal to 8 and less than 32')
										return
									}
									await axios.post(
										getUrl(`user/update_user_password`),
										{
											uid,
											password,
										},
										getDefaultOptions()
									)
									console.log({ uid, password, token: localStorage.getItem('token') })
									alert('Password changed')
								} catch (err) {
									console.error(err)
									alert('Changing password failed')
								}
								setIsVisible(false)
							}}
						>
							<input
								required
								minLength={8}
								onChange={(e) => {
									setPassword(e.target.value)
								}}
								type='password'
							/>
							{/* <button type='submit'>Submit</button> */}
							<Button
								onClick={async () => {
									return
								}}
								type='submit'
							>
								Submit
							</Button>{' '}
						</form>
					</div>
				</>
			) : (
				<>
					{/* {uid} */}
					<div>
						<Button onClick={() => setIsVisible(true)}>Edit Password</Button>
					</div>
				</>
			)}
		</>
	)
}

const AddUsersPage: React.FC = () => {
	const [show, setShow] = useState(false)
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()

	const [selectedUsers, setSelectedUsers] = useState([])

	React.useEffect(() => {
		console.log(selectedUsers)
	}, [selectedUsers])

	const [course, setCourse] = useState({
		uid: '',
		name: '',
	})
	const [courses, setCourses] = useState([
		{
			uid: '',
			name: '',
		},
	])

	React.useEffect(() => {
		const run = async () => {
			const courses = await listCourses()
			if (courses) setCourses(courses)
		}
		run()
	}, [])
	React.useEffect(() => console.log(course), [course])

	// React.useEffect(() => {
	// 	ApiLinks.get_users().then((data) => {
	// 		data = data.map((elem) => ({
	// 			id: elem.uid,
	// 			name: elem.first_name + ' ' + elem.last_name,
	// 			year: elem.external_email,
	// 			username: elem.username,
	// 			status: !elem.sent_mail ? 'Mail Sending Failed' : elem.username ? 'Success' : 'Fail',
	// 			statusIcon: elem.username ? 'success' : 'fail',
	// 		}))
	// 		setTableData(data)
	// 	})
	// }, [show])

	const update = useAppSelector((state) => state.ApplicationReducer.update)
	const dispatch = useAppDispatch()
	React.useEffect(() => {
		const run = async () => {
			const users = await getAllUsers()
			if (users) setTableData(users)
		}
		run()
	}, [update])

	React.useEffect(() => {
		const run = async () => {
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
	}, [])

	// React.useEffect(() => {
	// 	ApiLinks.get_courses()
	// 		.then((data) => {
	// 			console.log(data)
	// 			data = data.map((elem) => {
	// 				const updated = {
	// 					name: elem.course_name,
	// 					id: elem.uid,
	// 					year: elem.category ?? '',
	// 					status: elem.subCategory ?? 'success',
	// 					statusIcon: 'success',
	// 				}
	// 				// console.log(updated)
	// 				return updated
	// 			})
	// 			setCourses(data)
	// 		})
	// 		.catch((err) => console.error(err))
	// }, [])

	const [tableData, setTableData] = useState<Array<UserType>>([
		// {
		// 	id: 1,
		// 	name: '',
		// 	year: '',
		// },
		// {
		// 	id: 2,
		// 	name: 'Aniket Chowdhury',
		// 	year: 'SY',
		// 	status: 'success',
		// 	statusIcon: 'success',
		// },
		// {
		// 	id: 3,
		// 	name: 'Pushkar Asapure',
		// 	year: 'TY',
		// 	status: 'success',
		// 	statusIcon: 'success',
		// },
		// {
		// 	id: 4,
		// 	name: 'Pushkar Asapure',
		// 	year: 'TY',
		// 	status: 'fail',
		// 	statusIcon: 'fail',
		// },
	])
	const columns = [
		// {
		// 	text: 'Id',
		// 	dataField: 'id',
		// 	sort: true,
		// },
		{
			text: 'First Name',
			dataField: 'first_name',
			sort: true,
		},
		{
			text: 'Last Name',
			dataField: 'last_name',
			sort: true,
		},
		{
			text: 'Email',
			dataField: 'email',
			sort: true,
		},
		{
			text: 'Username',
			dataField: 'username',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			// formatter: (e: any) => {
			// 	return <UpdatePassword e={e}></UpdatePassword>
			// },
		},

		{
			text: 'Update Password',
			dataField: 'uid',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			formatter: (e: string) => {
				return <UpdatePassword e={e}></UpdatePassword>
			},
		},
		// {
		// 	text: 'Type',
		// 	dataField: 'type_of_user',
		// 	sort: true,
		// },
		{
			text: 'Status',
			dataField: 'uid',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			formatter: (e: string) => {
				// console.log(e)
				return (
					<>
						<div
							className='btn btn-primary'
							onClick={() => {
								navigate(`/admin/user/${e}`)
							}}
						>
							View
						</div>{' '}
					</>
				)
				// if (e === 'success') {
				// 	return <Icon icon='fa:fa-check' className='fa fa-check text-success'></Icon>
				// } else {
				// 	return <Icon icon='fa:fa-times' className='fa:fa-times text-danger'></Icon>
				// }
			},
		},
	]
	if (userData?.admin === true) {
		columns.push({
			text: 'Delete',
			dataField: 'uid',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			formatter: (e: string) => {
				return (
					<div
						onClick={async () => {
							if (window?.confirm('Are you sure you want to delete')) {
								if (window?.confirm('Are you sure you want to delete this use with uid:' + e)) {
									await handleApi(
										axios.delete(getUrl('user/?uid=' + e), {
											...getDefaultOptions(),
										})
									)
								}
							}
							dispatch(ApplicationStateActions.setUpdate())
						}}
						className='btn btn-danger'
					>
						Delete
					</div>
				)
			},
		})
	}

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const [modalData, setModalData] = useState({
		first_name: '',
		last_name: '',
		username: '',
		email: '',
		password: '',
	})

	return (
		<Fragment>
			<Fragment>
				{userData?.admin && (
					<div className='row mt-4 mx-2'>
						<div className='col-3'>
							<h2>Add Users</h2>
						</div>
						<div className='col-9 px-2'>
							<div className='float-right'>
								<button onClick={() => window.open('/user_sample.csv')} className='btn btn-primary'>
									<Icon icon='bx:bx-download' className='fa fa-download px-2 '></Icon>
									Download Sample File
								</button>
							</div>
							<div className='float-right px-2'>
								<button className='btn btn-primary' onClick={handleShow}>
									<Icon icon='fa:fa-plus' className='fa fa-plus px-2'></Icon>
									<span className='pl-2'>Add User</span>
								</button>
							</div>
							<div className='float-right px-2'>
								<button
									className='btn btn-primary'
									onClick={() => {
										navigate('./batch')
									}}
								>
									<Icon icon='fa:fa-plus' className='fa fa-plus px-2'></Icon>
									<span className='pl-2'>Batch Upload</span>
								</button>
							</div>
						</div>
					</div>
				)}

				<div className='row mt-4 mx-2'>
					<div className='col-12'>
						<div className='card'>
							<form onSubmit={(e) => e.preventDefault()}>
								<div className='card-header bg-white'></div>
								<div className='col-4'>
									{/* this is the place  */}
									{userData?.admin && (
										<Select
											options={courses.map((elem) => ({
												label: elem.name,
												value: elem,
												data: elem,
												id: elem.uid,
											}))}
											onChange={(e) => {
												if (e && e?.value) {
													setCourse(e.value)
												}
												console.log(e?.data)
											}}
										/>
									)}
									<br />
									<br />
									{userData?.admin && (
										<button
											className='btn btn-primary'
											onClick={async (e) => {
												e.preventDefault()
												await Promise.all(
													selectedUsers.map(async (user_id) => {
														console.log(user_id)

														return await addTeacher(user_id, course.uid)
													})
												)
												setSelectedUsers([])
												alert('Added Teachers')
											}}
										>
											<Icon icon='fa:fa-plus' className=' px-2'></Icon>
											<span className='pl-2'>Add Teacher</span>
										</button>
									)}

									<br />
									<br />
									{userData?.admin && (
										<button
											className='btn btn-primary'
											onClick={async (e) => {
												e.preventDefault()
												const data = await Promise.all(
													selectedUsers.map(async (user_id) => {
														console.log(user_id)

														return await addStudent(user_id, course.uid)
													})
												)
												console.log(data)
												setSelectedUsers([])
												alert('Added Students')
											}}
										>
											<Icon icon='fa:fa-plus' className='fa fa-plus px-2'></Icon>
											<span className='pl-2'>Add Student</span>
										</button>
									)}
								</div>
							</form>
							<div className='card-busernameody'>
								<AddClassesTable useSelect={[selectedUsers, setSelectedUsers]} data={tableData} columns={columns} />
							</div>
						</div>
					</div>
				</div>

				<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} size='lg' centered>
					<form
						onSubmit={async (e) => {
							e.preventDefault()
							await addUser(modalData)
							handleClose()
							dispatch(ApplicationStateActions.setUpdate())
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>Add User</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<Row></Row>

							<Row>
								<Col lg={6}>
									<Form.Group controlId='txtFirstName'>
										<Form.Label>First Name</Form.Label>
										<input
											className='form-control'
											required
											type='text'
											placeholder='First Name'
											onChange={(e) => {
												setModalData({
													...modalData,
													first_name: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>

								<Col lg={6}>
									<Form.Group controlId='txtLastName'>
										<Form.Label>Last Name</Form.Label>
										<input
											className='form-control'
											required
											type='text'
											placeholder='Last Name'
											onChange={(e) => {
												setModalData({
													...modalData,
													last_name: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>

								<Col lg={12}>
									<Form.Group controlId='txtUsername'>
										<Form.Label>Username</Form.Label>
										<input
											className='form-control'
											required
											type='text'
											placeholder='Username'
											onChange={(e) => {
												setModalData({
													...modalData,
													username: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>

								<Col lg={12}>
									<Form.Group controlId='txtEmail'>
										<Form.Label>Email</Form.Label>
										<input
											className='form-control'
											required
											type='email'
											placeholder='Email'
											onChange={(e) => {
												setModalData({
													...modalData,
													email: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>

								<Col lg={12}>
									<Form.Group controlId='txtExternalEmail'>
										<Form.Label>Password</Form.Label>
										<input
											className='form-control'
											required
											type='password'
											placeholder='Password'
											onChange={(e) => {
												setModalData((modalData) => ({
													...modalData,
													password: e.target.value,
												}))
											}}
										/>
									</Form.Group>
								</Col>
							</Row>
						</Modal.Body>

						<Modal.Footer>
							<Button variant='secondary' onClick={handleClose}>
								Close
							</Button>
							<Button
								type='submit'
								// onClick={async () => {
								// 	if (modalData.first_name !== '' && modalData.last_name !== '') {
								// 		const username =
								// 			modalData.first_name[0] +
								// 			modalData.last_name[0] +
								// 			`${new Array(5)
								// 				.fill(0)
								// 				.map(() => Math.floor(Math.random() * 10))
								// 				.reduce((a, b) => a + b.toString().toLowerCase(), '')}`
								// 		let _data = {
								// 			...modalData,
								// 			username,
								// 		}
								// 		console.log(await ApiLinks.add_user(_data))
								// 		alert('User added.')
								// 		handleClose()
								// 	}
								// }}
								variant='primary'
							>
								Save changes
							</Button>
						</Modal.Footer>
					</form>
				</Modal>
				<br />
				<br />
				<br />
				<br />
			</Fragment>
			<Link to='./batch'>
				<Button
					// onClick={async () => {
					// 	if (modalData.first_name !== '' && modalData.last_name !== '') {
					// 		const username =
					// 			modalData.first_name[0] +
					// 			modalData.last_name[0] +
					// 			`${new Array(5)
					// 				.fill(0)
					// 				.map(() => Math.floor(Math.random() * 10))
					// 				.reduce((a, b) => a + b.toString().toLowerCase(), '')}`
					// 		let _data = {
					// 			...modalData,
					// 			username,
					// 		}
					// 		console.log(await ApiLinks.add_user(_data))
					// 		alert('User added.')
					// 		handleClose()
					// 	}
					// }}
					variant='primary'
				>
					Batch Upload
				</Button>
			</Link>
		</Fragment>
	)
}

export default AddUsersPage
